.wii-home-survey-container{

    margin-block: 16px;
    padding: 8px;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        min-height: 700px;
        max-width: 585px;

        margin-inline: auto;
    }
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        min-height: 700px;
        min-width: 590px;
        max-width: 760px;

        margin-inline: auto;
    }
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        min-height: 700px;
        min-width: 760px;
        max-width: 990px;

        margin-inline: auto;
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        min-height: 700px;
        min-width: 890px;
        max-width: 1190px;

        margin-inline: auto;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        min-height: 750px;
        min-width: 1200px;

        margin-inline: auto;
    }
}

.wii-home-survey-title{
    font-size: 28px;
    font-weight: bold;
    font-family: 'popins-bold';

}

.wii-home-survey-grid{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 8px;
    row-gap: 8px;

     /* Extra small devices (phones, 600px and down) */
     @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {   
       grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.wii-home-survey-card{
   //width: 18.125rem;
   width: 100%;
   height: 18.125rem;
   border-radius: 0.25rem;

   align-self: center;
   margin-inline: auto;

   box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,0.2);
   transition: 0.3s;

}

.wii-home-survey-card-top{
    width: 100%;

    height: 5rem;

    display: flex;
    flex-direction: column;

    justify-content: space-between;

    padding-bottom: 5px;

}

.wii-home-survey-card-body{
    width: 100%;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wii-home-survey-card-members{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper-date{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
