@keyframes yc_shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes yc_bluer {
  0% {
    background-position-x: -30px;
  }
  25% {
    background-position-x: -60px;
  }
  50% {
    background-position-x: -80px;
  }
  75% {
    background-position-x: -60px;
  }
  100% {
    background-position-x: -30px;
  }
}

@keyframes yc_tab {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes yc-spin-avatar {
  0% {
    border: 3px solid #f3f3f3;
    border-top: 3px solid $primary-color;
  }
  30% {
    border: 3px solid #f3f3f3;
    border-top: 3px solid $primary-color;
  }
  60% {
    border: 3px solid #f3f3f3;
    border-right: 3px solid $primary-color;
  }
  90% {
    border: 3px solid #f3f3f3;
    border-bottom: 3px solid $primary-color;
  }
  100% {
    border: 3px solid #f3f3f3;
    border-left: 3px solid $primary-color;
  }
}
