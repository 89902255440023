.yc-input {
  line-height: 52px;
  height: 52px;
  padding: 0 17px;
  font-size: 14px;
  font-family: $font-regular $font-light;
  border-color: $primary-color;
  box-sizing: border-box;

  &-label {
    position: absolute;
    font-family: $font-bold;
    z-index: 1;
    top: -12px;
    left: 10px;
    right: 10px;
    background: #fff;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
    width: fit-content;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media screen and (max-width: 560px) {
      font-size: 12px;
    }
  }
}
.yc-input-div {
  margin-top: 10px;
  transition: all 3s ease-in-out;
}
.yc-input-shake {
  animation: yc_shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.yc-select-not {
  box-shadow: $shadow;
  &:hover {
    box-shadow: $shadow-hover;
  }
  line-height: 52px;
  height: 52px;
  padding: 0 17px;
  font-size: 14px;
  font-family: $font-regular $font-light;
  border-color: $primary-color;
}
