$shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
$shadow-hover: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
$primary-color: #0849b8;
$background: #f1f1f1;
$font-regular: "popins-regular";
$font-thin: "popins-thin";
$font-light: "popins-light";
$font-black: "popins-black";
$font-bold: "popins-bold";
$radius:10px;
