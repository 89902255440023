.iag-login {
    height: 100vh;
    top: 0;
    width: 100%;
    z-index: 200;
    background: #fff;
    position: fixed;
    transition: background cubic-bezier(0.445, 0.05, 0.55, 0.95) 1.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 300px;
      @media screen and (max-width: 767px) {
        width: 150px;
      }
    }
    .loading{
      margin: 30px 0px 0px 0px;
    }
  }

.iag-login-card{
    background-color: #fff;
    border: 0.3px solid rgba($color: #dadada, $alpha: 0.3);
    border-radius: 10px;
    height: 75%;
    box-shadow: 0px 0px 8px #dadada,
            0px 0px 8px #dadada,
            0px 0px 8px #dadada;
    
    @media (max-width:576px) {
        width: 95%
    }
    // 576 - 768px -- sm
    @media (min-width:576px) and (max-width:768px){
      width: 75%
    }
    @media (max-width:576px) {
      width: 100%;
  }
  // 576 - 768px -- sm
  @media (min-width:576px) and (max-width:768px){
    width: 88%;
    margin: 0px auto;
  }
  // 768- 992px -- md
  @media (min-width:768px) and (max-width:992px) {
    width: 80%;
    margin: 0px auto;
  }
  // 992- 1200px -- lg
  @media (min-width:992px) and (max-width:1200px) {
    width: 70%;
    margin: 0px auto;
  }
  // 1200 -- xl
  @media (min-width:1200px) {
    width: 60%;
    margin: 0px auto;
  }
    // 768- 992px -- md
    @media (min-width:768px) and (max-width:992px) {
      width: 50%;
    }
    // 992- 1200px -- lg
    @media (min-width:992px) and (max-width:1200px) {
      width: 45%;
    }
    // 1200 -- xl
    @media (min-width:1200px) {
      width: 40%;
    }
}

.iag-login-logo{
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    .logo-sign{
        margin: 10px auto;
    }
}

.iag-sign-button {
    line-height: 52px;
    @media (max-width:576px) {
        width: 100%;
    }
    // 576 - 768px -- sm
    @media (min-width:576px) and (max-width:768px){
      width: 88%;
      margin: 0px auto;
    }
    // 768- 992px -- md
    @media (min-width:768px) and (max-width:992px) {
      width: 80%;
      margin: 0px auto;
    }
    // 992- 1200px -- lg
    @media (min-width:992px) and (max-width:1200px) {
      width: 70%;
      margin: 0px auto;
    }
    // 1200 -- xl
    @media (min-width:1200px) {
      width: 60%;
      margin: 0px auto;
    }

    height: 48px;
    padding: 0 14px;
    font-size: 14px;
    font-family: $font-regular $font-light;
    border-color: $primary-color;
    border-radius: 24px;
    box-sizing: border-box;
    &-label {
      position: absolute;
      font-family: $font-bold;
      z-index: 1;
      top: -12px;
      left: 10px;
      right: 10px;
      background: #fff;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-wrap: break-word;
      width: fit-content;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      @media screen and (max-width: 560px) {
        font-size: 12px;
      }
    }
  }

  .iag-sign-left{
    width: 50vw;
    height:85vh;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    margin: auto;

    @media (max-width:576px) {
      width: 100vw;
      height:85vh;
      align-items:center;
      display: flex;
    }
    // 576 - 768px -- sm
    @media (min-width:576px) and (max-width:768px){
      width: 100vw;
      height:85vh;
      align-items:center;
      display: flex;
    }
    // 768- 992px -- md
    @media (min-width:768px) and (max-width:992px) {
      display: flex;
    }
    // 992- 1200px -- lg
    @media (min-width:992px) and (max-width:1200px) {
      display: flex;
    }
    // 1200 -- xl
    @media (min-width:1200px) {
      display: flex;
    }

    .iag-image-sign{
      margin:10px 0px;
      @media (max-width:576px) {
        display: flex;
      }
      // 576 - 768px -- sm
      @media (min-width:576px) and (max-width:768px){
        display: flex;
      }
      // 768- 992px -- md
      @media (min-width:768px) and (max-width:992px) {
        display: none;
      }
      // 992- 1200px -- lg
      @media (min-width:992px) and (max-width:1200px) {
        display: none;
      }
      // 1200 -- xl
      @media (min-width:1200px) {
        display: none
      }
  
    }
    .iag-title-sign{
      @media (max-width:576px) {
        display: none;
      }
      // 576 - 768px -- sm
      @media (min-width:576px) and (max-width:768px){
        display: none;
      }
      // 768- 992px -- md
      @media (min-width:768px) and (max-width:992px) {
        display: flex;
      }
      // 992- 1200px -- lg
      @media (min-width:992px) and (max-width:1200px) {
        display: flex;
      }
      // 1200 -- xl
      @media (min-width:1200px) {
        display: flex
      }
    }
  }

  .iag-wrapper-sign{
    @media (max-width:576px) {
      margin: 20px 0px;
    }
    // 576 - 768px -- sm
    @media (min-width:576px) and (max-width:768px){
      margin: 20px 0px;
    }
    // 768- 992px -- md
    @media (min-width:768px) and (max-width:992px) {
      margin: 25vh 0px;
    }
    // 992- 1200px -- lg
    @media (min-width:992px) and (max-width:1200px) {
      margin: 25vh 0px;
    }
    // 1200 -- xl
    @media (min-width:1200px) {
      margin: 25vh 0px;
    }
  }
  
  .iag-sign-right{
    width: 50vw;
    height:100vh;
    align-items:center;
    display: flex;

    @media (max-width:576px) {
      display: none;
    }
    // 576 - 768px -- sm
    @media (min-width:576px) and (max-width:768px){
      display: none;
    }
    // 768- 992px -- md
    @media (min-width:768px) and (max-width:992px) {
      display: flex;
    }
    // 992- 1200px -- lg
    @media (min-width:992px) and (max-width:1200px) {
      display: flex;
    }
    // 1200 -- xl
    @media (min-width:1200px) {
      display: flex;
    }

    .iag-image-wrapper{
      margin: 18vh 15vw;
    }

  }