@import "./searchbar";
@import "./searchbar-simple";

.yc-navbar {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  box-shadow: none;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  a:hover {
    transition: color cubic-bezier(0.175, 0.885, 0.32, 1.275) 3s;
    color: $primary-color;
  }
  .yc-active {
    border-bottom: 1px solid $primary-color;
    color: $primary-color;
    font-family: $font-black;
    @media screen and (max-width: 767px) {
      border-bottom: none;
    }
  }
  .ant-menu {
    box-shadow: none;
  }
}

.yc-index-navbar {
  display: flex;
  flex-wrap: nowrap;
  padding: 20px 10px;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 199;

  border-bottom: 0.03px solid #eaeaea;

  background: #fff;
  & + section,
  & + div {
    padding-top: 80px;
  }
  .yc-navbar-title {
    flex: 1;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700;
    font-family: $font-black;
    align-items: center;
  }
}
.yc-navbar-shadow {
  box-shadow: $shadow-hover;
}
.yc-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 20px;
}
.yc-menu-li {
  width: 100%;
  border-bottom: solid #ccc2 0.1px;
  a {
    color: $primary-color;
    width: 100%;
    display: inline-block;
    padding: 10px 15px;
  }
  &:last-child {
    border-bottom: none;
  }
  .yc-active {
    background-color: $primary-color;
    color: #fff;
  }
}

.yc-nav-btn {
  border: none;
  cursor: pointer;
}
.yc-nav-avatar {
  img {
    background-color: #f1f1f1;
  }
}

.yc-modal-searchbar {
  .ant-modal-footer {
    padding: 0;
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body {
    padding: 10px 0;
  }
}
