.yc-profil-header {
  min-height: 200px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.yc-profil-showing {
  padding-left: 300px !important;
  @media screen and (min-width: 1200px) {
    padding-left: 500px !important;
  }
  @media screen and (max-width: 991px) {
    padding-left: 100px !important;
  }
  @media screen and (max-width: 667px) {
    padding-left: 10px !important;
  }
}
.yc-profil-user-fixed {
  width: 300px;
  min-width: 300px;
  position: absolute;
  top: 200px;
  padding: 10px;
  @media screen and (min-width: 1200px) {
    width: 500px;
    min-width: 500px;
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
  & ~ div {
    padding-left: 300px;
    padding-right: 10px;
    @media screen and (min-width: 1200px) {
      padding-left: 500px !important;
    }
    @media screen and (max-width: 991px) {
      padding-left: 10px;
    }
    @media screen and (max-width: 667px) {
      padding-left: 10px;
    }
  }
}
.yc-profil-card {
  box-shadow: $shadow;
  @media screen and (max-width: 991px) {
    box-shadow: none;
    border: none;
    animation: yc_tab 5s cubic-bezier(1, 0, 0, 1);
    animation-iteration-count: 1;
  }
}
.yc-value-avatar {
  box-shadow: $shadow;
  background: #fff;
  color: #000;
}

.yc-loader-avatar {
  border: solid 1px #f3f3f3;
  border-radius: 50%;
  box-shadow: $shadow;
  &:hover .yc-profil-camera {
    display: flex;
  }
}
.yc-avatar-loading {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid $primary-color;
  border-radius: 50%;
  animation: yc-spin-avatar 0.5s linear infinite;
}

.yc-profil-camera {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  border-radius: 100%;
  display: none;
  align-items: center;
  justify-items: center;
  justify-content: center;
  background: #0004;
  transition: all ease 3s;
}
