  @media (min-width: 992px) {
   
  }
  
  @media (min-width: 768px) {
    .profile-nav-bg {
        margin-top: -82.8px;
    }
    .card-profile-head {
      margin: -47px 10px 10px;
    }
  }
  
  @media (min-width: 992px) {
    .card-billing-info.ant-card .ant-card-body {
      display: flex;
    }
  }
  
  @media (max-width: 768px) {
    .card-profile-head {
       margin: -47px 10px 10px;
    }
  }

.profile-nav-bg {
    height: 300px;
    margin-top: -137.8px;
    border-radius: 12px;
    box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
    background-size: cover;
    background-position: 50%;
}

.card-profile-head .ant-card-head {
    border-bottom: 0px;
    padding: 0px 10px;
  }
  
  .card-profile-head {
    margin: -47px 0 20px;
  }
  
  .card-profile-head .ant-card-head .ant-avatar {
    box-shadow: 0 5px 10px rgb(0 0 0 / 12%);
    border-radius: 8px;
    margin: 0;
  }
  
  .card-profile-head .ant-card-head .avatar-info {
    margin-left: 10px;
  }
  
  .ant-card-head-title p {
    font-weight: 600;
    color: #8c8c8c;
    margin-bottom: 0;
  }
  
  .card-profile-head .ant-card-head .avatar-info h4 {
    font-size: 24px;
  }
  
  .card-profile-head .ant-card-head .avatar-info h4 {
    font-size: 24px;
  }
  
  .card-profile-head .ant-radio-group .ant-radio-button-wrapper {
    height: auto;
    font-weight: 700;
  }
  .ant-radio-button-wrapper {
    color: #595959;
  }
  
  .layout-profile .ant-card-head {
    padding: 0 10px;
  }


.avatar-chips .ant-avatar-image {
    border: 1px solid #fff;
}

.avatar-chips .ant-avatar-image:hover,
.avatar-chips .ant-space-item:hover {
    z-index: 1;
}

.tootip-img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 1px solid #fff;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  
  .avatar-group .tootip-img + .tootip-img {
    margin-left: -10px;
  }
  
  .tootip-img:hover {
    z-index: 3;
    cursor: pointer;
  }
  
  .ant-avatar-group .ant-avatar-square {
    border-radius: 4px;
  }
  
  .shape-avatar {
    margin-right: 20px;
  }
  
  .shape-avatar img {
    border-radius: 4px;
  }
  
  .avatar-info h5 {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 3px;
  }
  
  .avatar-info p {
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
    margin: 0px;
  }

  .ant-avatar-group {
    align-items: center;
  }
  
  .mb-24 {
    margin-bottom: 24px;
  }

  .font-semibold {
    font-weight: 600;
  }

  .header-solid .ant-card-head {
    border-bottom: 0;
  }


  .card-project.ant-card {
    box-shadow: none;
    border: none;
  }
  
  .card-project.ant-card .ant-card-cover img {
    border-radius: 8px;
    box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
  }
  
  .card-project.ant-card .card-tag,
  .card-project.ant-card p {
    font-weight: 600;
    color: #8c8c8c;
    margin-bottom: 5px;
  }
  .card-project.ant-card h5 {
    font-weight: 600;
    font-size: 20px;
  }
  .card-project.ant-card .card-tag,
  .card-project.ant-card p {
    font-weight: 600;
    color: #8c8c8c;
    margin-bottom: 5px;
  }
  .card-project.ant-card p {
    margin-bottom: 0;
  }
  .card-project.ant-card .ant-card-body {
    padding: 10px 0 0;
  }
  
  .card-project.ant-card .card-footer {
    margin-top: 17px;
  }
  
  .avatar-chips .ant-avatar-image {
    border: 1px solid #fff;
  }
  .avatar-chips .ant-avatar-image:hover,
  .avatar-chips .ant-space-item:hover {
    z-index: 1;
  }
  
  .projects-uploader {
    height: 100%;
    border-radius: 8px;
    border-color: #d9d9d9;
  }
  .projects-uploader .ant-upload {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 8px;
    padding: 8px;
  }
  
  .projects-uploader .ant-upload-list.ant-upload-list-picture-card {
    height: 100%;
  }
  
  .projects-uploader .ant-upload.ant-upload-select-picture-card {
    background-color: transparent;
  }
  
  .projects-uploader svg {
    width: 20px;
    height: 20px;
  }
  