.scrollable-component {
    /* Transitions */
    --fade-in-transition-duration: 150ms;
    --fade-out-transition-duration: 800ms;
    --fade-out-transition-delay: 300ms;
    --fill-color-transition-duration: 150ms;
  
    /* Overflow behaviors */
    --viewport-overflow-x: auto;
    --viewport-overflow-y: auto;
  
    /* Scrolling behaviors */
    --viewport-scroll-snap-type: none;
    --viewport-scroll-behavior: auto;
    --viewport-overscroll-behavior: auto;
    --viewport-z-index: 0;
  
    /* Scrollbar look */
    scrollbar-width: inherit;
    --scrollbar-padding: 2px;
    --scrollbar-fill-color: transparent;
    --scrollbar-fill-color-hover: transparent;
    --scrollbar-border: 0 none;
    --scrollbar-border-radius: 0;
    --scrollbar-box-shadow: none;
    --scrollbar-z-index-hover: 30;
    --vertical-scrollbar-padding: var(--scrollbar-padding);
    --vertical-scrollbar-background: none;
    --vertical-scrollbar-background-size: auto;
    --vertical-scrollbar-z-index: 20;
    --horizontal-scrollbar-padding: var(--scrollbar-padding);
    --horizontal-scrollbar-background: none;
    --horizontal-scrollbar-background-size: auto;
    --horizontal-scrollbar-z-index: 10;

    --scrollbar-track-fill-color: transparent;
    --scrollbar-track-fill-color-hover: transparent;
    --scrollbar-track-border: 0 none;
    --scrollbar-track-border-radius: 0;
    --scrollbar-track-box-shadow: none;
    --vertical-scrollbar-track-background: none;
    --vertical-scrollbar-track-background-size: auto;
    --horizontal-scrollbar-track-background: none;
    --horizontal-scrollbar-track-background-size: auto;
  
    /* Scrollbar's thumb look */
    --scrollbar-thumb-fill-color: #ccc;
    --scrollbar-thumb-fill-color-hover: #aaa;
    --scrollbar-thumb-border: 0 none;
    --scrollbar-thumb-border-radius: var(--scrollbar-width);
    --scrollbar-thumb-box-shadow: none;
    --vertical-scrollbar-thumb-background: none;
    --vertical-scrollbar-thumb-background-size: auto;
    --horizontal-scrollbar-thumb-background: none;
    --horizontal-scrollbar-thumb-background-size: auto;
  
    /* Content padding */
    /* (You probably want to use this instead of setting the padding directly on the scrollable-component) */
    --content-padding: 0;
  }