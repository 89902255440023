@import "./navbar";
.yc-menu {
  padding: 0 20px;
  background: #fff;
  border-bottom: solid 1px #e8e8e8;
  overflow: auto;
  //box-shadow: $shadow-hover;
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    display: block;
  }
}
.yc-menu-fixed {
  position: sticky;
  top: 0;
  z-index: 199;
  display: flex;
}
.yc-logo {
  width: 150px;
  @media screen and (max-width: 767px) {
    width: 100px;
  }
  float: left;
  margin-left: -20px;
  a {
    display: inline-block;
    font-size: 20px;
    padding: 19px 20px;
    text-transform: capitalize;
    padding: 10px 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.yc-menu-con {
  width: calc(100% - 150px);
  float: left;
  display: flex;
  justify-content: end;
  flex: 1;
}

.yc-menu-con .ant-menu-item {
  padding: 0px 5px;
}

.yc-menu-con .ant-menu-submenu-title {
  padding: 10px 20px;
}

.yc-menu-con .ant-menu-item a,
.yc-menu-con .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.yc-menu-con .ant-menu-horizontal {
  border-bottom: none;
}

.yc-menu-con .yc-left-menu {
  float: left;
}

.yc-menu-con .yc-right-menu {
  float: right;
}

.yc-navigator {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

.yc-navigator-btn {
  display: block;
  width: 20px;
  height: 2px;
  background: $primary-color;
  position: relative;
}

.yc-navigator-btn:after,
.yc-navigator-btn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: $primary-color;
}

.yc-navigator-btn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.yc-navigator > span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media screen and (max-width: 767px) {
  .yc-navigator {
    display: inline-block;
  }

  .yc-left-menu,
  .yc-right-menu {
    display: none;
  }

  .yc-menu-con .ant-menu-item,
  .yc-menu-con .ant-menu-submenu-title {
    padding: 1px 20px;
  }
}
