.wii-home-main{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        min-height: 700px;
    }
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        min-height: 700px;
    }
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        min-height: 700px;
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        min-height: 700px;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        min-height: 750px;
    }
   
}