@font-face {
  font-family: popins-regular;
  src: url("../fonts/popins/Poppins-Regular.ttf");
}

@font-face {
  font-family: popins-light;
  src: url("../fonts/popins/Poppins-Light.ttf");
}

@font-face {
  font-family: popins-black;
  src: url("../fonts/popins/Poppins-Black.ttf");
}

@font-face {
  font-family: popins-bold;
  src: url("../fonts/popins/Poppins-Bold.ttf");
}

@font-face {
  font-family: popins-thin;
  src: url("../fonts/popins/Poppins-Thin.ttf");
}
