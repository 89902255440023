.wrapper-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1rem;
    margin: 1rem;
}

.container-explore{
    background-color: white;
    border-top: 0.125rem solid #007ACC;

    @media (min-width:320px) and (max-width:480px) {
        width: calc(100% - 0.125rem);
        margin-inline: auto;
    }
 
    @media (min-width:481px) and (max-width:768px) {
       width: calc(100% - 0.25rem);
       margin-inline: auto;
    }
 
    @media (min-width:769px) and (max-width:1024px) {
      width: calc(100% - 0.5rem);
      margin-inline: auto;
    }

    @media (min-width:1025px) and (max-width:1199px) {
        width: calc(100% - 1rem);
        margin-inline: auto;
    }
 
    @media (min-width:1200px){
       width: calc(100% - 1rem);
       margin-inline: auto;
    }
}