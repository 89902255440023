.yc-card {
  &-margin {
    margin: 5px 0;
  }
  box-shadow: $shadow;
  margin: 0;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  transition: box-shadow cubic-bezier(0.39, 0.575, 0.565, 1) 1s;
  transition: all 3s cubic-bezier(0.39, 0.575, 0.565, 1);
  &:hover .yc-card-image {
    object-position: 100% 40%;
  }
  &:hover {
    box-shadow: $shadow-hover;
  }
}
.yc-large {
  padding: 0;
}
.yc-card-mini-image {
  height: 80px;
  border-radius: 10px;
  width: 80px;
  object-fit: cover;
}
.yc-card-image-container {
  position: relative;
  div {
    position: static;
  }
}
.yc-card-image {
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  object-fit: cover;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  transition: all ease 1s;
}

.iag-content-grid{
  display: grid;
  grid-gap: 5px;
  padding: 4px;
  //0 - 576px -- xs
  @media (max-width:576px) {
    grid-template-columns: 99%;
  }
  // 576 - 768px -- sm
  @media (min-width:576px) and (max-width:768px){
    grid-template-columns: 99%;
  }
  // 768- 992px -- md
  @media (min-width:768px) and (max-width:992px) {
    grid-template-columns: 49% 49%;//repeat(1, 1fr);
  }
  // 992- 1200px -- lg
  @media (min-width:992px) and (max-width:1200px) {
    grid-template-columns: 49% 49%; 
    //grid-template-columns: repeat(2, 1fr);
  }
  // 1200 -- xl
  @media (min-width:1200px) {
    //grid-template-columns: 33% 33% 33%; 
    //grid-template-columns: repeat(3, 1fr);
  }
}

.iag-card-chart{
  //border: 1px solid #dadada;
  //border: 1px solid #e05;
  border-radius: 10px;
  background-color: #fff;
  padding: 4px;
  margin: 1px;
}

.iag-card-tags{
  padding: 5px;
  background-color: white;
}

.iag-tag-plus{
  background-color: #007ACC;
  color: white;
}

.iag-button-add{
  font-size: 15px;
  cursor: pointer;
}

.edit-tag{
  border-radius: 8px;
  margin: 0px 2px 4px 2px;
}