.yc-search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}
.yc-input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: $primary-color;
  padding-right: 40px;
  color: #000;
}
.yc-input-search::placeholder {
  color: rgba(100, 100, 100, 0.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}
.yc-btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: #fff;
  background-color: transparent;
  pointer-events: painted;
}
.yc-btn-search:focus ~ .yc-input-search {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.yc-navs {
  opacity: 0;
  visibility: hidden;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.yc-input-search:focus {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
