.yc-btn {
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  .ant-btn-primary {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}
.ant-btn {
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  height: 40px;
}
.ant-btn-primary {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  background: $primary-color !important;
  border-color: $primary-color;
}
.ant-btn-primary[disabled] {
  box-shadow: none;
  background: $background !important;
  border-color: $background;
}
.ant-btn-circle {
  border-radius: 100% !important;
}

.yc-hidden-small {
  @media screen and (max-width: 420px) {
    display: none;
  }
}

.ant-input-search-button {
  height: 32px !important;
}
