.yc-table {
  .ant-table-thead {
    background: $primary-color;
    border: none;
    box-shadow: $shadow !important;
    .ant-table-cell {
      background: transparent;
      font-family: $font-bold;
      color: #fff;
    }
  }
  .ant-table-row {
    cursor: pointer;
    .ant-table-cell-row {
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .ant-table-cell-row-hover {
      font-family: $font-light;
    }
  }
  .ant-pagination {
    padding: 0 10px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }
  box-shadow: $shadow;
  padding: 0;
}
