.iag-header-stat-root{
  //0 - 576px -- xs
  @media (max-width:576px) {
    display: block;
    .iag-header-stat-item{
      background-color: white;
      display: flex;
      align-items: center;
      align-content: space-around;
      width: 100%;
      border: 0.5px solid #eaeaea;
      border-radius: $radius;
      width: 100%;
      margin: 2px 0px;
      padding: 5px;
    }
  }
  // 576 - 768px -- sm
  @media (min-width:576px) and (max-width:768px){
    display: block;
    .iag-header-stat-item{
      background-color: white;
      display: flex;
      align-items: center;
      align-content: space-around;
      width: 100%;
      border: 0.5px solid #eaeaea;
      border-radius: $radius;
      width: 100%;
      margin: 2px 0px;
      padding: 5px;
    }
  }
  // 768- 992px -- md
  @media (min-width:768px) and (max-width:992px) {
    display: flex;
    .iag-header-stat-item{
      background-color: white;
      display: flex;
      align-items: center;
      align-content: space-around;
      width: 100%;
      border: 0.5px solid #eaeaea;
      border-radius: $radius;
      width: 25%;
      margin: 2px;
      padding: 5px;
    }
  }
  // 992- 1200px -- lg
  @media (min-width:992x) and (max-width:1200px) {
    display: flex;
    .iag-header-stat-item{
      background-color: white;
      display: flex;
      align-items: center;
      align-content: space-around;
      width: 100%;
      border: 0.5px solid #eaeaea;
      border-radius: $radius;
      width: 25%;
      margin: 2px;
      padding: 5px;
    }
  }
  // 1200 -- xl
  @media (min-width:1200px) {
    display: flex;
    .iag-header-stat-item{
      background-color: white;
      display: block;
      width: 100%;
      border: 0.5px solid #eaeaea;
      border-radius: $radius;
      width: 25%;
      margin: 2px;
      padding: 5px;
    }
  }
}

.iag-header-icon{
  width: 32px;
  margin-top: -10px;
}

.iag-row{
  display: flex;
  align-items: center;
}

.iag-grid .one{
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  padding: 4px;
}

.iag-grid .two{
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(2, 1fr);
  padding: 4px;
}

.iag-grid .three{
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  padding: 4px;
}

.iag-grid .four{
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  padding: 4px;
}

.iag-header-stat-count{
  font-size: 30px;
  font-weight: bold;
  color: rgba($color: #e05, $alpha: 0.8);
  margin:0px 10px
}

.iag-wrapper-chart-root{
  margin: 10px 0px;
  //0 - 576px -- xs
  @media (max-width:576px) {
    display: block;
  }
  // 576 - 768px -- sm
  @media (min-width:576px) and (max-width:768px){
    display: block;
  }
  // 768- 992px -- md
  @media (min-width:768px) and (max-width:992px) {
    display: flex;
  }
  // 992- 1200px -- lg
  @media (min-width:992px) and (max-width:1200px) {
    display: flex;
  }
  // 1200 -- xl
  @media (min-width:1200px) {
    display: flex;
  }
}

.iag-header-stat-row{
  //0 - 576px -- xs
  @media (max-width:576px) {
    display: block;
    align-items: center;
    align-content: space-around;
    width: 100%;
    border: 0.5px solid #eaeaea;
    border-radius: $radius;
    margin: 2px 0px;
    padding: 5px;
  }
  // 576 - 768px -- sm
  @media (min-width:576px) and (max-width:768px){
    display: block;
    align-items: center;
    align-content: space-around;
    width: 100%;
    border: 0.5px solid #eaeaea;
    border-radius: $radius;
    margin: 2px 0px;
    padding: 5px;
  }
  // 768- 992px -- md
  @media (min-width:768px) and (max-width:992px) {
    display: flex;
    align-items: center;
    align-content: space-around;
    border: 0.5px solid #eaeaea;
    border-radius: $radius;
    width: 33%;
    margin: 2px 2px;
    padding: 5px;
  }
  // 992- 1200px -- lg
  @media (min-width:992px) and (max-width:1200px) {
    display: flex;
    align-items: center;
    align-content: space-around;
    border: 0.5px solid #eaeaea;
    border-radius: $radius;
    width: 33%;
    margin: 2px 2px;
    padding: 5px;
  }
  // 1200 -- xl
  @media (min-width:1200px) {
    display: flex;
    align-items: center;
    align-content: space-around;
    border: 0.5px solid #eaeaea;
    border-radius: $radius;
    width: 33%;
    margin: 2px 2px;
    padding: 5px;
  }
}

.iag-header-stat-col{
  //0 - 576px -- xs
  @media (max-width:576px) {
    display: block;
    width: 100%;
    border: 0.5px solid #eaeaea;
    border-radius: $radius;
    margin: 2px 0px;
    padding: 5px;
  }
  // 576 - 768px -- sm
  @media (min-width:576px) and (max-width:768px){
    display: block;
    width: 100%;
    border: 0.5px solid #eaeaea;
    border-radius: $radius;
    margin: 2px 0px;
    padding: 5px;
  }
  // 768- 992px -- md
  @media (min-width:768px) and (max-width:992px) {
    display: flex;
    align-items: center;
    align-content: space-around;
    border: 0.5px solid #eaeaea;
    border-radius: $radius;
    width: 33%;
    margin: 2px 2px;
    padding: 5px;
  }
  // 992- 1200px -- lg
  @media (min-width:992px) and (max-width:1200px) {
    display: block;
    border: 0.5px solid #eaeaea;
    border-radius: $radius;
    width: 33.33%;
    margin: 2px 2px;
    padding: 5px;
  }
  // 1200 -- xl
  @media (min-width:1200px) {
    display: block;
    border: 0.5px solid #eaeaea;
    border-radius: $radius;
    width: 33.33%;
    margin: 2px 2px;
    padding: 5px;
  }
}

.col-2{
  display: block;
  @media (max-width:576px) {
   width: 100%;
  }
  // 576 - 768px -- sm
  @media (min-width:576px) and (max-width:768px){
    width: 100%;
  }
  // 768- 992px -- md
  @media (min-width:768px) and (max-width:992px) {
    width: 50%;
  }
  // 992- 1200px -- lg
  @media (min-width:992px) and (max-width:1200px) {
    width: 50%;
  }
  // 1200 -- xl
  @media (min-width:1200px) {
   width: 50%;
  }
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout{
  height: 100%;
}

.site-layout-background{
  background-color: #fff;
}


.iag-header{
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.separator{
  height: 30px;
  margin: 0px 10px;
  width: 1px;
  background-color: #dadada;
}

.avatar{
  align-self: flex-end;
}

.site-sider{
  min-height: 75vh;
  max-height: 100%;
  background-color: #fff;
}

.logo{
  height: 65px;
  background-color: #fff;
  background-image: url("../../../images/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 6px;
}
.without-logo{
  height: 65px;
  background-color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
}