.sticky {
  position: fixed;
  top: 70px;
  width: 100%;
  @media screen and (max-width: 767px) {
    top: 50px !important;
  }
  & + .content {
    padding-top: 60px;
  }
}
