  //0 - 576px -- xs
  @media (max-width:576px) {
    .iag-sider{
      background-color: white;
      display: none;
    }
    .iag-sider-visible{
      background-color: white;
      display: none;
    }
  }
  // 576 - 768px -- sm
  @media (min-width:576px) and (max-width:768px){
    .iag-sider{
        background-color: white;
        display: none;
    }
    .iag-sider-visible{
      background-color: white;
      display: none;
    }
  }
  // 768- 992px -- md
  @media (min-width:768px) and (max-width:992px) {
    .iag-sider{
        background-color: white;
        display: flex;
    }
    .iag-sider-visible{
      background-color: white;
      display: none;
    }
  }
  // 992- 1200px -- lg
  @media (min-width:992x) and (max-width:1200px) {
    .iag-sider{
        background-color: white;
        display: flex;
    }
    .iag-sider-visible{
      background-color: white;
      display: none;
    }
  }
  // 1200 -- xl
  @media (min-width:1200px) {
    .iag-sider{
        background-color: white;
        display: flex;
    }
    .iag-sider-visible{
      background-color: white;
      display: none;
    }
  }