.hidden{
    display: none;
}

.visible{
     /* Extra small devices (phones, 600px and down) */
     @media only screen and (max-width: 600px) {
        display: none;
    }
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        display: none;
    }
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        display: none;
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
       display: block;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        display: block;
    }
}

/* Decor de la page dashboard * */

.container-row{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.container-left{
    margin: 8px;
    padding: 8px;
    border-radius: 0.25rem;
    transition: 0.3s;

    display: none;

    @media (min-width:320px) and (max-width:480px) {
        display: none;
    }

    @media (min-width:481px) and (max-width:768px) {
        display: none;
    }

    @media (min-width:769px) and (max-width:1024px) {
        display: none;
    }

    @media (min-width:1025px){
        width: 250px;
        display: block;
    }
}

.container-center{
    margin-block: 8px;
    padding: 8px;
   
    min-height: 750px;

    @media (min-width:320px) and (max-width:480px) {
        width: 100%;
    }

    @media (min-width:481px) and (max-width:768px) {
        width: 100%;
    }

    @media (min-width:769px) and (max-width:1024px) {
        width: 100%;
    }

    @media (min-width:1025px){
        width: calc(100% - 500px);
    }
}

.container-right{
    margin-inline: 8px;
    margin-top: 30px;
    padding: 8px;
    border-radius: 0.25rem;
    transition: 0.3s;

   display: none;

   @media (min-width:320px) and (max-width:480px) {
       display: none;
   }

   @media (min-width:481px) and (max-width:768px) {
       display: none;
   }

   @media (min-width:769px) and (max-width:1024px) {
       display: none;
   }

   @media (min-width:1025px){
       width: 200px;
       display: block;
   }
}

.fixed-filter{
    background-color: white;
    margin: 8px;
    padding: 8px;
    position: fixed;
    top: 8;
    left: 0;
    width: 250px;
    height: 700px;
    overflow-y: scroll;
}

.fixed-sync{
    background-color: white;
    margin: 8px;
    padding: 8px;
    position: fixed;
    top: 100;
    right: 0;
    width: 200px;
    height: 450px;
    overflow-y: scroll;
}

.carte-map{
    height: 450px;
    width: 100%;
    background-color: white;
    margin-bottom: 8px;
}

.grid-quiz{
    display: grid;
   
    column-gap: 8px;
    row-gap: 8px;

    @media (min-width:320px) and (max-width:480px) {
        grid-template-columns: repeat(1,minmax(0,1fr));
    }
 
    @media (min-width:481px) and (max-width:768px) {
        grid-template-columns: repeat(1,minmax(0,1fr));
    }
 
    @media (min-width:769px) and (max-width:1024px) {
        grid-template-columns: repeat(1,minmax(0,1fr));
    }

    @media (min-width:1025px) and (max-width:1199px) {
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
 
    @media (min-width:1200px){
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
}

.row-sync{
    display: flex;
    margin-block: 4px;
    justify-content: space-between;
    align-items: start;
}

.total-gen{
    padding-inline: 3px;
    padding-block: 2px;
    border-radius: 8px;
    margin-inline-end: 5px;
    color: teal;
    font-family: inherit;
    font-size: 20px;
    font-weight: bold;
}

.total{
    padding-inline: 3px;
    padding-block: 2px;
    border-radius: 8px;
    margin-inline-end: 5px;
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.primary{
    background-color: transparent;
    color: #007ACC;
}

.secondary{
    background-color: transparent;
    color: peru;
}


/***
 @media only screen and (max-width: 600px) {
    min-height: 700px;
    max-width: 585px;

    margin-inline: auto;
}
@media only screen and (min-width: 600px) {
    min-height: 700px;
    min-width: 590px;
    max-width: 760px;

    margin-inline: auto;
}
@media only screen and (min-width: 768px) {
    min-height: 700px;
    min-width: 760px;
    max-width: 990px;

    margin-inline: auto;
}
@media only screen and (min-width: 992px) {
    min-height: 700px;
    min-width: 700px;
    max-width: 700px;

    margin-inline: auto;
}

@media only screen and (min-width: 1200px) {
    min-height: 750px;
    //min-width: 900px;
    min-width: 800px;
}

**/