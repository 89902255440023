.container-profile{
    background-color: white;
    align-self: center;
    @media (min-width:320px) and (max-width:480px) {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
 
    @media (min-width:481px) and (max-width:768px) {
       height: auto;
       display: flex;
       flex-direction: column;
       justify-content: start;
    }
 
    @media (min-width:769px) and (max-width:1024px) {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: start;
    }

    @media (min-width:1025px) and (max-width:1199px) {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
 
    @media (min-width:1200px){
       height: 200px;
       
       display: flex;
       flex-direction: column;
       justify-content: center;
    }
}

.wrapper-profile{

    @media (min-width:320px) and (max-width:480px) {
        width: calc(100% - 0.125rem);
        display: flex;
        flex-direction: column;
        margin-inline: auto;
    }
 
    @media (min-width:481px) and (max-width:768px) {
       width: calc(100% - 0.25rem);
       display: flex;
       flex-direction: column;
       margin-inline: auto;
    }
 
    @media (min-width:769px) and (max-width:1024px) {
      width: calc(100% - 0.5rem);
      display: flex;
      flex-direction: column;
      margin-inline: auto;
    }

    @media (min-width:1025px) and (max-width:1199px) {
        width: calc(100% - 300px);
        margin-inline: auto;
        height: 150px;
        display: flex;
        align-items: center;
    }
 
    @media (min-width:1200px){
       width: calc(100% - 300px);
       height: 150px;
       display: flex;
       align-items: center;
       margin-inline: auto;
    }
}

.w-picture{
    height: 150px;
    width: 150px;
    border-radius: 75px;
    border: 1px solid #dadada;
    margin-inline: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width:320px) and (max-width:480px) {
        margin-block-start: 16px;
    }
 
    @media (min-width:481px) and (max-width:768px) {
        margin-block-start: 16px;
    }
 
    @media (min-width:769px) and (max-width:1024px) {
        margin-block-start: 16px;
    }

    @media (min-width:1025px) and (max-width:1199px) {
        margin-block-start: 16px;
    }
 
    @media (min-width:1200px){
        margin-block-start: 16px;
    }

    img{
        height: 150px;
        width: 150px;
        border-radius: 75px;
        object-fit: cover;
    }
}

.info-name{
    margin: 0;
}
.info-enterprise{
    font-size: 13px;
    font-weight: 500;
}

.info-date{
    font-size: 12px;
    margin-block-end: 11px;
    font-weight: 500;
}

.badge-info{
    min-width: 250px;
    padding-inline: 0.125rem;
    padding-block: 0.075rem;
    background-color: #fafafa;
}

.quick-contact{
   
    @media (min-width:320px) and (max-width:480px) {
        width: calc(100% - 0.125rem);
        display: flex;
        flex-direction: column;
        margin-inline: auto;
        margin-block-start: 30px;
    }
 
    @media (min-width:481px) and (max-width:768px) {
       width: calc(100% - 0.25rem);
       display: flex;
       flex-direction: column;
       margin-inline: auto;
       margin-block-start: 30px;
    }
 
    @media (min-width:769px) and (max-width:1024px) {
      width: calc(100% - 0.5rem);
      display: flex;
      flex-direction: column;
      margin-inline: auto;
      margin-block-start: 30px;
    }

    @media (min-width:1025px) and (max-width:1199px) {
        width: calc(100% - 300px);
        margin-inline: auto;
        height: 150px;
        display: flex;
        align-items: center;
        margin-block-start: 30px;
    }
 
    @media (min-width:1200px){
       width: calc(100% - 300px);
       height: 150px;
       display: flex;
       align-items: center;
       margin-inline: auto;
       margin-block-start: 30px;
    }
}

.info-perso-gender{
  display: flex;
  align-items: center;

  .title{
    font-size: 28px;
    color: black;
    font-weight: bold;
    margin-inline: 16px;
  }

  .val{
    font-size: 16px;
    font-weight: normal;
  }
}

.info-perso{
    display: flex;
    margin-inline: 16px;
    column-gap: 4px;
}

.perso-title{
    font-weight: bold;
}