.iag-splash {
  height: 100vh;
  top: 0;
  width: 100%;
  z-index: 200;
  background: #fff;
  position: fixed;
  transition: background cubic-bezier(0.445, 0.05, 0.55, 0.95) 1.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 300px;
    @media screen and (max-width: 767px) {
      width: 120px;
    }
  }
  .loading{
    margin: 30px 0px 0px 0px;
  }
}
