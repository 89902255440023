@import "./var";
@import "./keyframes";
@import "./font";
@import "./components//me/index.scss";
@import "./components/navbar/navigator";
@import "./components/home/index";
@import "./components/home/main.scss";
@import "./components/home/input";
@import "./components/home/profil";
@import "./components/home/chart";
@import "./components/home/splash";
@import "./components/sticky/sticky";
@import "./components/tab/tabs";
@import "./components/table/table";
@import "./components/btn";
@import "./components/sider/index";
@import "./components/login/index";
@import "./components/card/card";
@import "./components/profile/index";
@import "./components/scroll/index";
@import "./components/home/survey/index.scss";
@import "./components/home/profile/index.scss";
@import "./components/loader/index.scss";


* {
  font-family: roboto, popins-light, popins-regular;
}
