.paper-container{
    width: calc(100% - 30px);
    margin-inline: auto;
    padding-inline: 10px;
    padding-block-end: 10px;
    margin-block: 5px;
}

.paper-total{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80px;

    .paper-total-title{
        margin: 0;
        font-weight: bold;
    }

    .paper-total-value{
        margin: 0;
        font-size: 28px;
        font-weight: bold;
        color: teal;
    }
}

.analytic-value{
    font-size: 16px;
    font-weight: bold;
    color: #007ACC;
}